// max valuest
$max-tablet-width: 991px;
$max-mobile-width-sm: 767px;
$max-mobile-width-xs: 575px;
$max-mobile-width-xxs: 376px;

// min values
$min-tablet-width: 768px;
$min-mobile-width-sm: 576px;

// default values 
$input-border-radius: 5px; // border radius of textboxes and textareas
