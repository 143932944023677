@import './ks-colors.scss';
@import './ks-dimensions.scss';

$font-size: 12px !default;

@mixin font-base($font-size) {
  font-size: $font-size;
}

@mixin font-light($font-size: $font-size) {
  @include font-base($font-size);
  font-weight: 300;
}

@mixin font-regular($font-size: $font-size) {
  @include font-base($font-size);
  font-weight: 400;
}

@mixin font-medium($font-size: $font-size) {
  @include font-base($font-size);
  font-weight: 500;
}

@mixin font-semi-bold($font-size: $font-size) {
  @include font-base($font-size);
  font-weight: 600;
}

@mixin font-bold($font-size: $font-size) {
  @include font-base($font-size);
  font-weight: 700;
}

@mixin font-black($font-size: $font-size) {
  @include font-base($font-size);
  font-weight: 900;
}

@mixin background-position-size($background-position: center, $background-size: cover) {
  background-position: $background-position !important;
  background-size: $background-size !important;
}

@mixin width-height($width, $height) {
  width: $width;
  height: $height;
}

@mixin responsive-width($desktop-width, $mobile-width) {
  width: $desktop-width;

  @media (max-width: $max-mobile-width-sm) {
    width: $mobile-width;
  }
}

@mixin margin($margin-top: 0, $margin-right: 0, $margin-bottom: 0, $margin-left: 0) {
  margin-top: $margin-top;
  margin-right: $margin-right;
  margin-bottom: $margin-bottom;
  margin-left: $margin-left;
}

@mixin margin-all($margin: 0) {
  @include margin($margin, $margin, $margin, $margin);
}

@mixin margin-tb-lr($margin-top-bottom: 0, $margin-left-right: 0) {
  @include margin($margin-top-bottom, $margin-left-right, $margin-top-bottom, $margin-left-right);
}

@mixin padding($padding-top: 0, $padding-right: 0, $padding-bottom: 0, $padding-left: 0) {
  padding-top: $padding-top;
  padding-right: $padding-right;
  padding-bottom: $padding-bottom;
  padding-left: $padding-left;
}

@mixin padding-all($padding: 0) {
  @include padding($padding, $padding, $padding, $padding);
}

@mixin padding-tb-lr($padding-top-bottom: 0, $padding-left-right: 0) {
  @include padding($padding-top-bottom, $padding-left-right, $padding-top-bottom, $padding-left-right);
}

@mixin flex($justify-content, $align-center, $flex-column) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-center;
  flex-direction: $flex-column;
}

@mixin position-float-top-left($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
}

@mixin position-float-bottom-right($bottom, $right) {
  position: absolute;
  bottom: $bottom;
  right: $right;
}

@mixin position-float-bottom-left($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

@mixin table-width($width) {
  width: $width;
  max-width: $width;
  min-width: $width;
}

@mixin general-text-input($font-size) {
  @include font-regular($font-size);
  @include padding-all(10px);
  width: 100%;
  border: 1px solid lightgray;
  border-radius: $input-border-radius;
  outline: none;
}