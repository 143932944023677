$white: #fff;
$black: #000;

$grey: gray;
$light-grey-1: #f1f1f1;
$light-grey-2: #f6f6f6;

$green-1: #b9b872;

$dark-blue-1: #455a64;

$general-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;